import { routes as auth } from '@views/auth'
import { routes as administration } from '@views/administration'
import { routes as messages } from '@views/organization/messages'
import { routes as documents } from '@views/organization/documents'
import { routes as contractors } from '@views/organization/contractors'
import { routes as user } from '@views/user'
import { routes as orgs } from '@views/organization/orgs'
import { routes as utd } from '@views/organization/utd' // ToDo: Убрать после нормального подключения конструктора УПД
import { routesApplications } from '@views/organization/applications'

import { IRoute } from '@common/Routing/components/RouteWithSubRoutes/types'

export const authRoutes: IRoute[] = [...auth]

export const privateRoutes: IRoute[] = [
  ...user,
  ...documents,
  ...messages,
  ...contractors,
  ...administration,
  ...orgs,
  ...routesApplications,
  ...utd // ToDo: Убрать после нормального подключения конструктора УПД
]
