import Loadable from 'react-loadable';
import { IRoute } from '@app/common/Routing/components/RouteWithSubRoutes/types';
import { AllRoles, Path } from '@app/const/consts';
import { SpinnerWrapper } from '@app/containers/Navigation';

const ApplicationView = Loadable({
  loader: () =>
    import('@views/organization/applications/components/ApplicationView'),
  loading: SpinnerWrapper,
});

export const routesApplications: IRoute[] = [
  {
    path: Path.APPLICATION_VIEW,
    exact: true,
    name: 'Application',
    component: ApplicationView,
    roles: AllRoles,
    useRootMode: true,
  },
];
