import { IApplicationState, ModuleThunkDispatch } from '@app/store/types/commonTypes';
import { AxiosResponse } from 'axios';
import { Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';

export interface IApplication {
  /** Название приложения */
  name: string;
  /** Идентификатор иконки приложения */
  icon: number;
  /** PROD-URL приложения */
  url: string;
}

export enum ApplicationsActionTypes {
  SET_APPLICATIONS = 'SET_APPLICATIONS',
  ADD_APPLICATION = 'ADD_APPLICATION',
}

export interface IActionSetApplications extends Action<string> {
  payload: IApplication[];
  type: ApplicationsActionTypes.SET_APPLICATIONS;
}

export interface IActionAddApplication extends Action<string> {
  payload: IApplication;
  type: ApplicationsActionTypes.ADD_APPLICATION;
}

export type Actions = IActionSetApplications | IActionAddApplication;

type ActionKeys = 'setApplications' | 'addApplication';

export type ApplicationsActions = {
  [key in ActionKeys]: ActionCreator<Action<string>>;
};

export type ApplicationsThunkAction = ThunkAction<
  Promise<AxiosResponse>,
  IApplicationState,
  void,
  Action<string>
>;

// export type ApplicationsThunkDispatch = ThunkDispatch<IApplication[], void, Action<string>>

export type ApplicationsThunkDispatch = ModuleThunkDispatch<IApplicationState>
