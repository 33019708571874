import { AxiosResponse } from 'axios'
import cn from 'classnames'
import { Formik } from 'formik'
import fileDownload from 'js-file-download'
import { DateTime } from 'luxon'
import React, { FC, Fragment, ReactElement, ReactNode } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { Button, Ellipsis, getSurnameWithInitials, IconAttach, ILibUser } from '@infologistics/frontend-libraries'
import AnswerForm from '@common/Messages/AnswerForm/AnswerForm'

import {
  createUrl,
  displayErrorNotification,
  displaySuccessNotification
} from '@utils/utils'

import {
  DateFormat,
  DocumentType,
  RouteName,
  ShowMode,
  SuccessCode,
  TaskStatus,
  temporaryPostResponseCode
} from '@const/consts'
import { Tasks } from '@const/translations'

import attachmentsService from '@services/attachments'
import flowsService from '@services/flows'

import {
  IApplicationState,
  IParams,
  ISuccessfulRequest,
  Nullable
} from '@store/types/commonTypes'
import { IMessageTask, IMessage } from '@store/modules/tasks/types'
import { IDocument, ITodoAttachment } from '@store/modules/documents/types'
import { ICloseMessageBody, IMessageBody } from '@views/personal/components/Tasks/Messages/types'
import { ICompleteMessageData, IMessageProps as IProps, ISendMessageData } from './types'
import { IAnswerFormState} from '@common/Messages/AnswerForm/types'
import { ITodoFile } from '@views/organization/documents/components/Document/components/Tabs/Todos/components/TodoForm/types'
import { getAllBadges } from '@store/modules/navigation/actions'
import {
  getMessages,
  markAsRead,
  setLoadedFromActionButtonsAction,
  toggleResponse,
  updateResponse
} from '@store/modules/messages/actions'
import { useLoading } from '@app/hooks/useLoading'
import { changeOrganization, resetStoreAfterOrganizationChange } from '@store/modules/user/actions'
import { getDictTypes } from '@store/modules/metadata/actions'
import { getDivisions } from '@store/modules/divisions/actions'
import { setLoaded } from '@store/modules/startup/actions'

import styles from './Message.module.css'

const completeTaskParams: ICloseMessageBody = {
  comment: null,
  result: TaskStatus.SOLVED
}

const Message: FC<IProps> = (props) => {
  const {
    message,
    isCompleted
  } = props

  const { t } = useTranslation()
  const dispatch = useDispatch<any>()
  const history = useHistory()

  const { activeOrganization } = useSelector((state: IApplicationState) => state.user)
  const { responses } = useSelector((state: IApplicationState) => state.messages)

  const onGetBadges = ():Promise<AxiosResponse> => dispatch(getAllBadges())
  const onGetMessages = (params: IParams): Promise<AxiosResponse> => dispatch(getMessages(params))
  const onSendMessage = (data: ISendMessageData, orgOguid: string): Promise<AxiosResponse> =>
    flowsService.sendMessage(data.documentOguid, data.body, orgOguid)
  const onToggleResponse = (oguid: string): void => dispatch(toggleResponse(oguid))
  const onUpdateResponse = (oguid: string, text: string): void => dispatch(updateResponse(oguid, text))
  const onMarkAsRead = (taskOguid: string, orgOguid: string): Promise<AxiosResponse> => dispatch(markAsRead(taskOguid, orgOguid))
  const onCompleteMessage = (data: ICompleteMessageData, orgOguid: string): Promise<AxiosResponse> => (
    flowsService.complete(data.oguid, data.body, orgOguid)
  )

  const {
    document,
    org,
    task
  } = message
  const { attachment, isRead, oguid, startedTimestamp, author } = task
  const orgOguid = org?.oguid ?? ''

  const response = responses[oguid] || {}
  const { isOpen = false } = response

  const className = cn(styles.message, !isRead && styles.new, isOpen && styles.is_open, 'p-4')

  const renderMessageNumDate = (document: IDocument): string => {
    const { fields } = document

    if (fields) {
      const { documentDate, documentNumber } = fields

      const number = documentNumber && typeof documentNumber !== 'object' ? ` № ${String(documentNumber)}` : ''

      const date = documentDate ? ` ${t('common:from')} ${DateTime.fromMillis(+documentDate).toFormat(DateFormat.DATE_FULL_YEAR)}` : ''

      return `${number}${date}`
    }

    return ''
  }

  const renderMessageTextarea = (oguid: string, documentOguid: string, author: ILibUser): ReactNode => {
    const formState: IAnswerFormState = {
      file: null,
      oguid,
      documentOguid,
      author,
      description: ''
    }

    const validationSchema = Yup.object().shape({
      description: Yup.string().required(Tasks.enterMessage)
    })

    return (
      <Formik
        initialValues={formState}
        onSubmit={handleMessageSubmit}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {(props) => <AnswerForm orgOguid={orgOguid} {...props} />}
      </Formik>
    )
  }

  const renderButtons = (oguid: string): ReactNode => {
    if (isCompleted) return null

    return (
      <div className='mt-2'>
        <Button
          // 'undefined' is needed for 'isDisabled' to work correctly
          // it doesn't work with loading=false
          // TODO remove 'undefined' after fix in the library
          loading={loadingSendMessage || undefined}
          isDisabled={loadingCompleteMessage}
          onClick={handleReplyClick(oguid)}
          theme='primary'
          size='small'
          externalClass='mr-4'
          >
            {loadingSendMessage ? t('tasks:buttons.sending') : t('tasks:buttons.reply')}
        </Button>
        <Button
          loading={loadingCompleteMessage || undefined}
          isDisabled={loadingSendMessage}
          onClick={() => handleCloseButtonClick(oguid)}
          size='small'>
          {t('tasks:buttons.close')}
        </Button>
      </div>
    )
  }

  const handleCloseButtonClick = (oguid: string): void => {
    fetchCompleteMessage({
      data: {
        oguid,
        body: completeTaskParams
      }
    })
  }

  const renderMessageAuthor = (author: ILibUser): ReactElement => {
    if (!author) return <span>{t('tasks:withoutAuthor')}</span>

    const position = author?.position && ` (${author.position})`

    return (
      <>
        {getSurnameWithInitials(author)}
        {position}
      </>
    )
  }

  const renderDocumentInfo = (message: IMessage): ReactElement => {
    const { document } = message

    if (!document) return <span>{t('tasks:withoutDocument')}</span>

    return (
      <div className='text-muted font-xs d-flex mb-2'>
        <span className='text-nowrap mr-1'>{t('tasks:document')}</span>
        {renderMessageThemeLink(message)}
      </div>
    )
  }

  const renderMessageText = (message: IMessage): ReactNode => {
    const { task } = message
    const { description } = task

    return <p className={cn(styles.message_text, 'font-sm mb-0')}>{description && description}</p>
  }

  const renderMessageAttachment = (attachment: Nullable<ITodoAttachment>): ReactNode => {
    if (!attachment) return null

    return (
      <p className='d-flex align-items-center mt-2 mb-0'>
        <IconAttach classes='mr-1' size='xs' />
        <Button
          classes={styles.button_download}
          onClick={handleFileDownload(attachment)}
          size='extra-small'
          theme='link'
        >
          <Ellipsis>{attachment.fileName}</Ellipsis>
        </Button>
      </p>
    )
  }

  const handleFileDownload = (attachment: ITodoAttachment) => async (): Promise<void> => {
    const { fileName, mimeType, oguid } = attachment

    try {
      const resp = await attachmentsService.getDocumentFile(oguid, orgOguid)

      fileDownload(resp.data, fileName, mimeType)
    } catch (err) {
      displayErrorNotification(err)
    }
  }

  const renderMessageThemeLink = (message: IMessage): ReactNode => {
    const { document, title } = message
    const { fields, type } = document
    const { filename } = fields

    const typeFilename =
      type === DocumentType.NONFORMALIZED && typeof filename === 'string' ? filename : title

    const linkTitle = `${typeFilename}${renderMessageNumDate(document)}`

    return (
      <Button theme='link' linkStyle='none' onClick={handleDocumentLinkClick(message)}>
        <Ellipsis title={linkTitle} externalClass='font-xs'>
          {linkTitle}
        </Ellipsis>
      </Button>
    )
  }

  const handleDocumentLinkClick = ({ org, document }: IMessage) => () => {
    if (!org) return

    const { alias, oguid } = org
    const { documentId } = document
    const docPath = `${RouteName.DOCUMENTS}/${documentId}`
    const organizationIdentifier = alias || oguid

    if (oguid === activeOrganization.oguid) {
      history.replace(createUrl(activeOrganization.alias, docPath))
      return
    }

    dispatch(resetStoreAfterOrganizationChange())
    dispatch(changeOrganization(org))
    dispatch(getDictTypes())
    dispatch(getDivisions())

    history.replace(createUrl(organizationIdentifier, docPath))

    dispatch(setLoaded(false))
  }

  const handleMarkAsRead = (task: IMessageTask) => () => {
    const { isRead, oguid } = task

    if (isRead) return

    return onMarkAsRead(oguid, orgOguid).catch(displayErrorNotification)
  }

  const handleCompleteTask = (data: ICompleteMessageData): Promise<AxiosResponse> => {
    dispatch(setLoadedFromActionButtonsAction(true))
    return onCompleteMessage(data, orgOguid)
      .then(async (resp: AxiosResponse) => {
        if (resp.status === temporaryPostResponseCode.POST_200) await handleRefresh().catch(displayErrorNotification)
        return resp
      })
      .finally(() => dispatch(setLoadedFromActionButtonsAction(false)))
  }

  const [loadingCompleteMessage, fetchCompleteMessage] = useLoading<ICompleteMessageData, ISuccessfulRequest>(handleCompleteTask)

  const handleReplyClick = (oguid: string) => () => {
    if (!responses[oguid].isOpen) onToggleResponse(oguid)
  }

  const handleMessageSubmit = (values: IAnswerFormState): void => {
    const { file, oguid, description, documentOguid } = values

    const body = getMessageBody(file, oguid, description)

    if (body.description.length) {
      fetchSendMessage({ data: { documentOguid, body } })
    }
  }

  const handleSendSubmit = (data: ISendMessageData): Promise<AxiosResponse> => {
    dispatch(setLoadedFromActionButtonsAction(true))
    return onSendMessage(data, orgOguid).then(async (resp: AxiosResponse) => {
      if (SuccessCode.PUT.includes(resp.status)) {
        onToggleResponse(oguid)
        onUpdateResponse(oguid, '')
        await handleRefresh().catch(displayErrorNotification)
        displaySuccessNotification({ title: t('tasks:messageSent') })
      }
      return resp
    })
      .finally(() => dispatch(setLoadedFromActionButtonsAction(false)))
  }

  const [loadingSendMessage, fetchSendMessage] = useLoading<ISendMessageData, ISuccessfulRequest>(handleSendSubmit)

  const getMessageBody = (file: Nullable<ITodoFile>, oguid: string, description: string): IMessageBody => {
    return {
      assignedToGroupOguid: null,
      assignedToUserOguid: responses[oguid].recipient.oguid,
      description: description,
      parentTaskOguid: oguid,
      fileOguid: file?.oguid ?? null
    }
  }

  const getParams = (): IParams => {
    return {
      page: -1,
      showMode: ShowMode.TODOS_ONLY,
      isCompleted: isCompleted
    }
  }

  const handleRefresh = async (): Promise<void> => {
    const params = getParams()

    await Promise.all([onGetMessages(params), onGetBadges()]).catch(displayErrorNotification)
  }

  return (
    <Fragment key={oguid}>
      <div className={className} onClick={handleMarkAsRead(task)}>
        <div className='grid-3 grid-middle font-default mb-2'>
          <p className={cn('grid-item-2 mb-0', styles.message_author)}>
            <Ellipsis>{renderMessageAuthor(author)}</Ellipsis>
          </p>
          <p className='grid-item font-xs text-right text-muted mb-0'>
            {DateTime.fromMillis(startedTimestamp).toFormat(DateFormat.DATETIME_FULL_YEAR)}
          </p>
        </div>

        {renderDocumentInfo(message)}

        {renderMessageText(message)}

        {renderMessageAttachment(attachment)}

        {!isOpen && renderButtons(oguid)}
      </div>
      {renderMessageTextarea(oguid, document.oguid, author)}
    </Fragment>
  )
}

export default Message
