import { AxiosResponse } from 'axios';

import AbstractHttpService from '../abstractHttpService';
import urls from '@app/const/urls';
import { IApplication } from '@app/store/modules/applications/types';

class ApplicationsService extends AbstractHttpService {
  private readonly url = urls.applications;

  async getList(): Promise<AxiosResponse<IApplication[]>> {
    const url = this.url.get;

    return await this._http.get(url)
  }
}

export default new ApplicationsService()
