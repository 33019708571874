import { AxiosError, AxiosResponse } from 'axios';
import { Action, ActionCreator } from 'redux';

import applicationsService from '@services/applications';

import { ResponseCode } from '@const/consts';

import {
  ApplicationsActionTypes,
  ApplicationsActions,
  ApplicationsThunkDispatch,
  IActionAddApplication,
  IActionSetApplications,
  IApplication,
} from './types';
import { AsyncThunk } from '@app/store/types/commonTypes';

const setApplicationsAction: ActionCreator<Action> = (
  data: IApplication[]
): IActionSetApplications => ({
  payload: data,
  type: ApplicationsActionTypes.SET_APPLICATIONS,
});

export const addApplicationsAction: ActionCreator<Action> = (
  data: IApplication
): IActionAddApplication => ({
  payload: data,
  type: ApplicationsActionTypes.ADD_APPLICATION,
});

export const actions: ApplicationsActions = {
  setApplications: setApplicationsAction,
  addApplication: addApplicationsAction,
};

export const getApplications: AsyncThunk =
  () =>
  (dispatch: ApplicationsThunkDispatch): Promise<AxiosResponse> =>
    applicationsService
      .getList()
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.setApplications(resp.data));
        }

        return resp;
      })
      .catch((error: AxiosError) => Promise.reject(error));

// export const addApplication: ActionCreator<ApplicationsThunkAction> =
//   (appication: IApplication) =>
//   (dispatch: ApplicationsThunkDispatch): Promise<void> =>
//     new Promise<void>((resolve) => {
//       dispatch(actions.addApplication(appication));
//       resolve();
//     });
