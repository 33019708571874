import * as Sentry from '@sentry/browser'
import React, { Component, ReactNode } from 'react'
import { AxiosResponse } from 'axios'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import TokensService from '@services/tokensService'

import {
  BrowserStorage,
  DOCUMENT_CARD_REGEXP,
  ErrorCode,
  Instance,
  Languages,
  LocaleType,
  lookupLocalStorage,
  UserSettingsKey
} from '@const/consts'

import { displayErrorNotification, getLanguageFromInstance, getLanguageName, toggleRootModeInUserSettings } from '@utils/utils'

import { SpinnerWrapper } from '@containers/Navigation'

import {
  getFields,
  getDocumentTypeGroups,
  getWorkflowStatuses,
  getFlowStageTypes,
  getFlowStageNames,
  getHistoryStateCodes,
  getUserDocTypes
} from '@store/modules/metadata/actions'
import { getAllBadges, getBadges, getBadgesNotices } from '@store/modules/navigation/actions'
import { logout } from '@store/modules/root/actions'
import { setLoaded } from '@store/modules/startup/actions'
import { getUserFilters } from '@store/modules/userFilters/actions'
import {
  getUserExtendedInfo,
  getProfile,
  getUserSettings,
  updateProfile,
  getLogoOrg,
  actions
} from '@store/modules/user/actions'
import { setErrorOrgs, setLanguage, toggleRootMode } from '@store/modules/utils/actions'

import { IApplicationState } from '@store/types/commonTypes'
import { IUpdatedProfile } from '@store/modules/user/types'
import { IProfile } from '@store/modules/user/types'
import {
  IStartupProps as IProps,
  IStartupPropsFromState as IPropsFromState,
  IStartupPropsFromDispatch as IPropsFromDispatch
} from './types'
import { getApplications } from '@app/store/modules/applications/actions'

class Startup extends Component<IProps> {
  public componentDidMount(): void {
    TokensService.setTab()

    TokensService.clearBeforeUnloadTab()

    this.authUser().catch(displayErrorNotification)
  }

  public shouldComponentUpdate(nextProps: Readonly<IProps>): boolean {
    const { asideWidth, isLoaded, isRootMode, language, orgOguid, themeClass } = this.props

    return (
      asideWidth !== nextProps.asideWidth ||
      isLoaded !== nextProps.isLoaded ||
      isRootMode !== nextProps.isRootMode ||
      language !== nextProps.language ||
      orgOguid !== nextProps.orgOguid ||
      themeClass !== nextProps.themeClass
    )
  }

  public componentDidUpdate(prevProps: IProps): void {
    const { isLoaded, isRootMode, language, orgOguid, setLoaded, themeClass, onGetLogoOrg } = this.props

    const authorized = TokensService.checkAccessToken()

    if (!orgOguid && !isLoaded && prevProps.isLoaded) {
      this.authUser().catch(displayErrorNotification)
    }

    if (authorized && (
      prevProps.orgOguid && prevProps.orgOguid !== orgOguid
    ) || (
      orgOguid && !isRootMode && prevProps.isRootMode !== isRootMode
    )) {
      Promise.all(this.getPromises(false, true))
        .then(setLoaded)
        .catch((err) => {
          if (err.response && err.response.status && err.response.status === ErrorCode.IP_RESTRICTION) {
            setLoaded()
          } else {
            displayErrorNotification(err);
          }
        })
        onGetLogoOrg().catch(() => actions.setLogoOrg(null))
    }

    if (authorized && prevProps.language !== language) {
      Promise.all(this.getPromises(true))
        .then(setLoaded)
        .catch(displayErrorNotification)
    }

    // theme change
    if (prevProps.themeClass !== themeClass) {
      document.body.className = themeClass
    }
  }

  render(): ReactNode {
    const { children, isLoaded } = this.props

    return isLoaded ? children : <SpinnerWrapper />
  }

  private readonly authUser = async (): Promise<void> => {
    const {
      onGetAllBadges,
      onGetBadges,
      onGetBadgesNotices,
      onGetLogoOrg,
      onGetProfile,
      onSetRootMode,
      setLoaded,
      onSetErrorOrgs,
      userEmail,
      userOguid,
    } = this.props

    const { accessToken, refreshToken } = TokensService.getTokensToStartup()

    // If there are valid tokens, then user authorization
    if (accessToken ?? refreshToken) {
      try {
        // If the access token is missing or expired for some reason,
        // then update it based on a valid refresh token
        if (refreshToken && !accessToken) {
          const isGenerateNewRefresh = TokensService.isGenerateNewRefresh(refreshToken)

          await TokensService.refresh(refreshToken, isGenerateNewRefresh)
        }

        // User authorization actions
        const settings = await this.getUserSettingsWithClearOrgs()
        const asideWidth = settings.data[UserSettingsKey.ASIDE_WIDTH]
        const rootMode = settings.data[UserSettingsKey.ROOT_MODE]

        const lastOrg = Object.keys(settings.data?.orgs ?? {}).find(org => settings.data.orgs[org][UserSettingsKey.IS_LAST]) ?? ''
        const { data } = await onGetProfile(lastOrg)

        if (asideWidth) {
          document.documentElement.style.setProperty('--aside-width', asideWidth)
        }

        if (rootMode && data.orgs.length > 1) {
          onSetRootMode()
        } else if (rootMode && data.orgs.length === 1) {
          toggleRootModeInUserSettings(false)
          await Promise.all(this.getPromises())
        }

        if (!data.orgs.length) {
          onSetErrorOrgs();
          setLoaded()

          return
        }

        Sentry.configureScope((scope) => {
          scope.setUser({
            email: userEmail ?? '',
            id: userOguid
          })
        })

        if (!rootMode) await onGetAllBadges()
        await onGetBadges()
        await onGetBadgesNotices()

        if (!rootMode) {
          await Promise.all(this.getPromises())
          await onGetLogoOrg().catch(() => actions.setLogoOrg(null))
        } else await Promise.all(this.getPromises(false, false, true))

        this.handleSetLanguage(data)
      } catch (err) {
        displayErrorNotification(err)
      }
    }

    setLoaded()
  }

  private readonly getUserSettingsWithClearOrgs = async (): Promise<AxiosResponse> => {
    const { onGetUserSettings } = this.props

    const settings = await onGetUserSettings()
    Object.keys(settings.data?.orgs ?? {})?.forEach((org) => {
      delete settings.data.orgs[org].navMode
      delete settings.data.orgs[org].asideWidth
    })

    return settings
  }

  private readonly handleSetLanguage = (data: IProfile): void => {
    const { i18n, instance, onSetLanguage } = this.props
    const { locale, timeZone } = data

    const instanceValue = localStorage.getItem(BrowserStorage.INSTANCE) ?? instance ?? Instance.GLOBAL
    const languageFromInstance = getLanguageFromInstance(locale, instanceValue)
    const localeFromLocalStorage = localStorage.getItem(lookupLocalStorage) ?? getLanguageName(navigator.language)
    let language = locale ? getLanguageName(locale) : localeFromLocalStorage

    if (!locale || !timeZone) {
      const localeToSet = locale ?? LocaleType[localeFromLocalStorage.toUpperCase()]
      const timeZoneToSet = timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone

      this.handleUpdateProfile(data, localeToSet, timeZoneToSet)
    }

    if (languageFromInstance === LocaleType.RU) {
      language = Languages.RU
    }

    if (languageFromInstance === LocaleType.EN_US || languageFromInstance === LocaleType.EN_GB) {
      language = Languages.EN
    }

    if (languageFromInstance === LocaleType.PT) {
      language = Languages.PT
    }

    i18n
      .changeLanguage(language)
      .then(() => {
        localStorage.setItem(lookupLocalStorage, language)
        onSetLanguage(language)
      })
      .catch(displayErrorNotification)
  }

  private readonly handleUpdateProfile = (data: IProfile, locale: string, timeZone: string): void => {
    const { onUpdateProfile, setLoaded } = this.props
    const { email, login, name, patronymic, phone, surname } = data

    const password = null

    onUpdateProfile({ email, login, name, patronymic, phone, surname, timeZone, password, locale })
      .then(setLoaded)
      .catch(displayErrorNotification)
  }

  private readonly getPromises = (isUpdate?: boolean, isChangeOrg?: boolean, isRootMode?: boolean): Array<Promise<AxiosResponse>> => {
    const {
      lastLocation
    } = this.props

    const {
      onGetBadges,
      onGetUserFilters,
      onGetFields,
      onGetFlowStageNames,
      onGetFlowStageTypes,
      onGetHistoryStateCodes,
      onGetDocTypeGroups,
      onGetWorkflowStatuses,
      onGetUserDocTypes,
      onGetUserExtendedInfo,
      onGetApplications
    } = this.props

    if (isRootMode) {
      return [
        onGetFlowStageTypes()
      ]
    }

    if (isUpdate) {
      return [
        onGetDocTypeGroups(),
        onGetFlowStageNames(),
        onGetFlowStageTypes(),
        onGetHistoryStateCodes(),
        onGetUserDocTypes(),
        onGetWorkflowStatuses()
      ]
    }

    const promises = [
      onGetFields(),
      onGetFlowStageNames(),
      onGetHistoryStateCodes(),
      onGetDocTypeGroups(),
      onGetUserDocTypes(),
      onGetWorkflowStatuses(),
      onGetUserFilters(),
      onGetUserExtendedInfo(),
      onGetApplications(),
    ]

    if (!isChangeOrg) promises.push(onGetFlowStageTypes())

    if (isChangeOrg && !lastLocation.match(DOCUMENT_CARD_REGEXP)) promises.push(onGetBadges())

    return promises
  }
}

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  asideWidth: state.utils.asideWidth,
  isLoaded: state.app.isLoaded,
  isRootMode: state.utils.isRootMode,
  language: state.utils.language,
  orgOguid: state.user.activeOrganization.oguid,
  themeClass: state.utils.themeClass,
  userEmail: state.user.profile.email,
  userOguid: state.user.profile.oguid,
  instance: state.utils.instance,
  lastLocation: state.utils.lastLocation,
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onGetAllBadges: () => dispatch(getAllBadges()),
  onGetBadges: () => dispatch(getBadges()),
  onGetBadgesNotices: () => dispatch(getBadgesNotices()),
  onGetDocTypeGroups: () => dispatch(getDocumentTypeGroups()),
  onGetFields: () => dispatch(getFields()),
  onGetFlowStageNames: () => dispatch(getFlowStageNames()),
  onGetFlowStageTypes: () => dispatch(getFlowStageTypes()),
  onGetHistoryStateCodes: () => dispatch(getHistoryStateCodes()),
  onGetLogoOrg: () => dispatch(getLogoOrg()),
  onGetUserExtendedInfo: () => dispatch(getUserExtendedInfo()),
  onGetUserFilters: () => dispatch(getUserFilters()),
  onGetProfile: (lastOrg?: string) => dispatch(getProfile(lastOrg)),
  onGetUserDocTypes: () => dispatch(getUserDocTypes()),
  onGetWorkflowStatuses: () => dispatch(getWorkflowStatuses()),
  onGetUserSettings: () => dispatch(getUserSettings()),
  onLogout: () => dispatch(logout()),
  onSetLanguage: (language: string) => dispatch(setLanguage(language)),
  onSetRootMode: () => dispatch(toggleRootMode(true)),
  onUpdateProfile: (profile: IUpdatedProfile) => dispatch(updateProfile(profile)),
  setLoaded: () => dispatch(setLoaded()),
  onSetErrorOrgs: () => dispatch(setErrorOrgs()),
  onGetApplications: () => dispatch(getApplications()),
})

const StartupClass = connect(mapStateToProps, mapDispatchToProps)(Startup)

export default withTranslation()(StartupClass)
